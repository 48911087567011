<template>
    <div>  
        <div class="row w-100" style="margin-right:0px;margin-left:0px;margin-bottom:16px">
            <div class="col-md-10 " style="padding-left:0px">
                <!-- DESKTOP -->
                <div class="card shadow d-sm-block d-none"   style="margin-top: 10px;height:130px;">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center e">
                            <div class="col mr-2">
                                <div class="font-weight-bold text-ecomm h3 mb-1">{{ allenamentoSelezionato.DESCRIZIONE }} 
                                    <button class="btn btn-grid btn-warning"><i class="fal fa-pencil"></i> Modifica</button>
                                    <button class="btn btn-grid btn-info ml-2"><i class="fal fa-sync"></i> Seleziona un altro allenamento</button>
                                    <button v-on:click="print()" class="btn btn-grid btn-danger ml-2"><i class="fal fa-print"></i> Stampa</button>
                                </div>
                                <div class="h6 mb-0 mt-2  text-gray-800">
                                    <strong>Note</strong> {{ allenamentoSelezionato.NOTE }}<br/>
                                    <strong>Data</strong> {{ allenamentoSelezionato.DATA_ALLENAMENTO }}
                                </div> 
                            </div> 
                            <div class="col-auto"><i class="fal fa-clipboard-list-check fa-fw fa-4x" style="color:#0679ab;"></i>
                            </div>
                        </div>
                       
                    </div>
                </div>
                
                <!-- MOBILE -->
                 <div class="d-block d-sm-none mt-4">
                   <h2 style="width:100%;text-align:center"> {{ allenamentoSelezionato.DESCRIZIONE }} </h2>
                   <h3 style="width:100%;text-align:center">{{ allenamentoSelezionato.DATA_ALLENAMENTO }}</h3>
                </div>
            </div>
            
            <!-- DESKTOP -->
            <div class="col-md-2 d-sm-block d-none" style="padding-right:0px;">
                <div class="card shadow " style="margin-top: 10px;height:130px;background:#36b9cc;color:#fff">
                    <div class="card-body">
                        <div style="font-size:17px;text-align:center;margin-top:8px">
                            <div class="font-weight-bold text-ecomm h3 mb-1"><i class="fal ml-2 fa-stopwatch"></i> {{  this.filters.formattaTempoNs(getDurataTotaleAllenamento(allenamentoSelezionato.serie)) }}</div>
                            <div class="font-weight-bold text-ecomm h3 mb-1"><i class="fal fa-flip-horizontal fa-swimmer fa-fw"></i> {{ filters.formattaImportoInt(getDistanzaTotaleAllenamento(allenamentoSelezionato.serie)) }} mt</div> 
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 p-0  d-block d-sm-none">
                <!-- MOBILE -->
                <div class="card shadow" style="margin-top: 10px;height:100px;background:#36b9cc;color:#fff">
                    <div class="card-body">
                        <div style="font-size:17px;text-align:center;margin-top:0px">
                            <div class="font-weight-bold text-ecomm h3 mb-1"><i class="fal ml-2 fa-stopwatch"></i> {{  this.filters.formattaTempoNs(getDurataTotaleAllenamento(allenamentoSelezionato.serie)) }}</div>
                            <div class="font-weight-bold text-ecomm h3 mb-1"><i class="fal fa-flip-horizontal fa-swimmer fa-fw"></i> {{ filters.formattaImportoInt(getDistanzaTotaleAllenamento(allenamentoSelezionato.serie)) }} mt</div> 
                        </div>
                    </div>
                </div>

            </div>
        </div>
        
        <button v-on:click="nuovaSerie()" class="btn btn-primary" v-show="countSelezionate > 1"><i class="fal fa-object-group"></i> Raggruppa e ripeti</button>
        <draggable tag="div" :disabled="$root.isMobile == true" :list="allenamentoSelezionato.serie" class="row w-100" style="margin-left: 0px; margin-right: 0px;" @end="salvaOrdine($event)">
            <div v-for="(serie,index) in allenamentoSelezionato.serie" :data-serie="serie.ID_SERIE" v-bind:key="serie.ID_SERIE" class="col-md-3">
                <Serie @updateserie="getAllenamento()" v-model="allenamentoSelezionato.serie[index]"></Serie>
            </div>
        </draggable>
        <button class="btn btn-success float-right btn-lg d-sm-block d-none" v-on:click="nuovaSerie()" style="margin-top:26px"><i class="fal fa-plus"></i> Nuova serie</button> 
        <button class="btn btn-success btn-block mb-4 p-2 btn-lg d-block d-sm-none" v-on:click="nuovaSerie()" style="margin-top:26px"><i class="fal fa-plus"></i> Nuova serie</button> 

    </div>
</template>
<script>

import DatePicker from '@/components/utils/DatePicker.vue'
import Serie from '@/components/ns/Serie.vue'
import { VueDraggableNext } from 'vue-draggable-next'  



export default {
    name:"Allenamento",
    components:{
        Serie,
        draggable: VueDraggableNext,
    },
    data:function(){
        return{
            filters:global.filters,
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:50},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''}, 
                CODICE_ARTICOLO:{TYPE:'TEXT',VALUE:''}, 
            },
            searchCr:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:18},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
            },
            arrayAllenamenti : new Array(),
            arrayCentriRicavo : new Array(),
            allenamentoSelezionato : {serie:new Array()},
            ultimaSerieAperta:0
        }
    },
    methods: {
        getAllenamento : function(){
            utils.get("ns/allenamento",{ID_ALLENAMENTO:this.$root.ID_ALLENAMENTO_SELEZIONATO} , (response) => {
                this.allenamentoSelezionato = response.data;
                for (var i = 0 ; i < this.allenamentoSelezionato.serie.length ; i++) {
                    if (this.allenamentoSelezionato.serie[i].ID_SERIE == this.ultimaSerieAperta){
                        this.allenamentoSelezionato.serie[i].aperto = true;
                        if (this.allenamentoSelezionato.serie[i].serie.length == 1){
                            this.allenamentoSelezionato.serie[i].serie[0].aperto = true;
                        }
                        break;
                    }
                }
            });
        },
        modificaAllenamento : function(allenamento){
            utils.get("ns/allenamento", {ID_ALLENAMENTO:this.$root.ID_ALLENAMENTO_SELEZIONATO} , (response) => {
                this.allenamentoSelezionato = response.data;
                var modal = $('#popUpAllenamento');
                modal.modal('show');
            });
        },
        salvaAllenamento : function(){
            const metodo = this.allenamentoSelezionato.ID_ALLENAMENTO > 0 ? utils.put : utils.post;
            metodo("ns/allenamento",this.allenamentoSelezionato,(response)=>{
                utils.alert.success(response.messaggio);
                this.getAllenamenti();
            });
        },

        nuovaSerie : function(){
            utils.post("ns/serie",{ID_SERIE:0,FK_ID_ALLENAMENTO:this.allenamentoSelezionato.ID_ALLENAMENTO,FLG_BLOCCO:1,FK_ID_SERIE:0,NUM_RIPETUTE:1} , (response) => {
                if (response.esito == 0){
                    this.ultimaSerieAperta = response.id_inserito;
                    this.getAllenamento();
                }
            });
        },
        getDurataTotaleAllenamento(serie){
            var tempoTotale = 0;
            for (var i = 0; i < serie.length ; i++){
                var s = serie[i];
                if (s.serie.length == 0){
                    var tempo = s.DURATA_SINGOLA * s.NUM_RIPETUTE;
                    if (!isNaN(tempo)){
                        tempoTotale += tempo;
                    }   
                }
                else{
                    tempoTotale += this.getDurataTotaleAllenamento(s.serie) * s.NUM_RIPETUTE;
                }                              
            }
            return tempoTotale;
        },

        getDistanzaTotaleAllenamento : function(serie){
            var distanzaTotale = 0;
            for (var i = 0; i < serie.length ; i++){
                var s = serie[i];
                if (s.serie.length == 0){
                    var distanza = s.DISTANZA_SINGOLA * s.NUM_RIPETUTE;
                    if (!isNaN(distanza)){
                        distanzaTotale += distanza;
                    }                    
                }
                else{
                    distanzaTotale += this.getDistanzaTotaleAllenamento(s.serie) * s.NUM_RIPETUTE;
                }
            }
            return distanzaTotale;
        },
        salvaOrdine : function(event){
            console.log(event);

            var idSerieFrom = $(event.item).data()['serie'];
            var idSerieTo = $(event.originalEvent.target).parent().data();
            console.log(idSerieFrom);
            /*
            this.eventoDaSpostare = $(event.item).data()['evento'];
            this.tipologiaEventoDaSpostare = $(event.item).data()['tipologia'];*/

            for (var i = 0 ; i < this.allenamentoSelezionato.serie.length ; i++){
                this.allenamentoSelezionato.serie[i].ORDINE = i+1;
            }
            utils.post("ns/allenamento/ordine",this.allenamentoSelezionato,(response)=>{
                utils.alert.success(response.messaggio);
            });
        },
        print : function(){
            window.open(utils.BASE_API + "core/report?ID_REPORT=1007&ID_ALLENAMENTO="+this.allenamentoSelezionato.ID_ALLENAMENTO);
        }
       
    },
    computed : {
        countSelezionate : function(){
            return utils.array.select(this.allenamentoSelezionato.serie,"SELEZIONATO",1).length;
        }        
    },
    created : function(){
        this.getAllenamento();  
    },
}
</script>