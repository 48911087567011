<template>
    <div class="card mt-2 shadow"  v-bind:style="getStyleBorderColorSerie(serie)" >   
        <div class="card-header" v-on:click="headerClickHandler($event)">
            <div style=" height: 50px;border: none; color:#fff; text-align: left;;padding: 2px;font-size:16px" v-bind:style="getStyleColoreSerie(serie)">                
              
                <div class="float-left"  style="font-weight:bold;font-size:30px;border-right:1px solid#fff;padding-right:10px;padding-left:4px">
                    <span v-if="serie.serie.length == 0">{{filters.nsGetLblRegime(serie.FK_ID_INTENSITA)}}</span>
                    <template v-else>
                        <span v-for="(s,index) in serie.serie" v-bind:key="s.ID_SERIE">{{filters.nsGetLblRegime(s.FK_ID_INTENSITA)}}<span v-show="index < serie.serie.length -1">+</span></span>
                    </template>
                </div>
                <div class="float-left" >
                    <strong style="padding-left:6px;"><i class="ml-1 fal fa-stopwatch"></i> <span >{{ this.filters.formattaTempoNs(getDurataTotaleSerie(serie)) }}</span></strong>
                    <br/>
                    <strong style="padding-left:6px;"><i class="fal fa-flip-horizontal fa-swimmer"></i> <span> {{ filters.formattaImportoInt(getDistanzaTotaleSerie(serie)) }} mt</span></strong>                            
                </div>       

                <div class="dropdown float-right mr-2 ml-2"  style="margin-top:6px" v-show="$root.utente.FK_ID_LIVELLO == 1">
                    <button class="btn btn-dark btn-sm" type="button"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fal fa-bars"></i> 
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
                        <button class="dropdown-item"  v-show="serie.FK_ID_SERIE == 0"  v-on:click="nuovaSerie()"><i class="fal fa-plus"></i> Nuova serie</button>                      
                        <button class="dropdown-item" v-on:click="deleteSerie(serie)"><i class="fal fa-trash-alt"></i> Elimina serie</button> 
                    </div>
                </div>   
            </div>
        </div>   
        <div class="card-body" v-show="serie.aperto" style="padding:0px">            
            <div class="w-100 p-3 mb-4 mt-2" v-if="serie.serie.length == 0 && serie.FK_ID_SERIE > 0">                    
                <div class="float-left" >
                    <select class="form-control" v-model="serie.NUM_RIPETUTE"  style="min-width:70px;float:left;font-weight:bold">
                        <option v-for="i in 100" v-bind:value="i" v-bind:key="i"> {{ i }}</option>
                    </select>
                </div>
                <div class="float-left ml-2 mt-2" >x</div>                
                <div class="float-left  ml-2 "  >
                    <select class="form-control " v-model="serie.DISTANZA_SINGOLA" style="font-weight:bold">
                        <option v-for="distanza in $root.arrayDistanze" v-bind:key="distanza" v-bind:value="distanza">{{ distanza }}</option>
                    </select>
                </div>    
                <div class="float-left ml-2" >@</div>                
                <div class="float-left mr-2 ml-2 "  >
                    <Tempo  v-model="serie.DURATA_SINGOLA" :mostraCentesimi="false"></Tempo>                
                </div>      
            </div>
            <div class="w-100 p-3" v-if="serie.serie.length == 0 && serie.FK_ID_SERIE > 0">
                <div class="mr-2" >
                    <div class="btn-group mr-2 w-100" role="group" aria-label="First group">
                        <button v-on:click="serie.FK_ID_STILE = stile.ID_STILE" v-for="stile in $root.arrayStili" v-bind:key="stile.ID_STILE" style="height:36px" type="button" class="btn btn-sm" v-bind:class="classBtn(stile.ID_STILE,serie.FK_ID_STILE)" >{{ stile.DESCRIZIONE }}</button>
                    </div>                                        
                </div>
                <div class="mr-2 mt-3"  >
                    <div class="btn-group mr-2 w-100" role="group" aria-label="First group">
                        <button v-on:click="serie.FK_ID_INTENSITA = intensita.ID_INTENSITA" v-bind:key="intensita.ID_INTENSITA" v-for="intensita in $root.arrayIntensita" style="height:36px" type="button" class="btn btn-sm" v-bind:class="classBtn(intensita.ID_INTENSITA,serie.FK_ID_INTENSITA)" >{{ intensita.DESCRIZIONE }}</button>
                    </div>                  
                </div>
                <div class="mr-2 mt-3">
                    <div class="btn-group mr-2 w-100" role="group" aria-label="First group">
                        <button v-on:click="serie.FK_ID_MODALITA = mod.ID_MODALITA" v-for="mod in $root.arrayModalita" v-bind:key="mod.ID_MODALITA"  style="height:36px" type="button" class="btn btn-sm" v-bind:class="classBtn(mod.ID_MODALITA,serie.FK_ID_MODALITA)" >{{ mod.DESCRIZIONE }}</button>
                    </div>                  
                </div>   
            </div>            
            <div v-bind:style="getStileDivSerieChildren()" v-if="serie.serie.length > 0" >
                <select class="form-control" v-model="serie.NUM_RIPETUTE"  style="font-weight:bold;margin-bottom:12px;margin-top:12px" @change="salvaSerie(serie)">
                    <option v-for="i in 100" v-bind:value="i" v-bind:key="i"> {{ i }}</option>
                </select>
                <draggable tag="div" :list="serie.serie" @end="salvaOrdine($event)" :disabled="$root.isMobile == true">
                    <Serie @updateserie="scatenaUpdate()" v-for="(s,index) in serie.serie" v-model="serie.serie[index]" v-bind:key="s.ID_SERIE" :data-serie="s.ID_SERIE" :data-madre="serie.ID_SERIE" style="margin-bottom:16px"></Serie> 
                </draggable>
                
            </div>            
        </div>
        <div class="card-footer">
            <div class="w-100"  v-show="serie.aperto == true" >
                <div style="margin-top:6px" v-show="serie.serie.length == 0 && serie.FK_ID_SERIE > 0">
                    <textarea type="text" style="height:60px" class="form-control " placeholder="NOTE" v-model="serie.DESCRIZIONE"></textarea>
                </div>
                <button class="btn-block btn btn-success btn-lg" v-show="serie.serie.length == 0 && serie.FK_ID_SERIE > 0" style="margin-top:6px" v-on:click="salvaSerie(serie)"><i class="fal fa-check"></i> Salva</button>
            </div>   
            <div class="row w-100" style="font-weight:bold" v-show="serie.aperto == false" v-html=" filters.nsFormatSerie(serie)"></div>
  
                                
        </div>
    </div> 
 
</template>

<script>
    
    import { computed } from 'vue'
    import Tempo from '@/components/ns/Tempo.vue'
    import { VueDraggableNext } from 'vue-draggable-next'  


    export default {
        name:"Serie",
        components:{
            Tempo,
            draggable: VueDraggableNext,
        },
        props: {
            modelValue: {
                type: Object,
                default: () => ({}),
            },
        },
        emits: ['update:modelValue','updateserie'],
        setup(props, { emit }) {
            const serie = computed({
                get: () => props.modelValue,
                set: (val) => emit('update:modelValue', val),
            });
            return { serie };
        },
        data:function(){
            return{
                filters:global.filters,
                aperto:false
            }
        },
        methods: {
            getDurataTotaleSerie : function(serie){
                if (serie.serie.length == 0){
                    var tempo = serie.DURATA_SINGOLA * serie.NUM_RIPETUTE;
                    if (!isNaN(tempo)){
                        return tempo;
                    }
                }
                else{
                    var tempoTot = 0;
                    for (var i = 0 ; i < serie.serie.length ; i++){
                        var tempo = serie.serie[i].DURATA_SINGOLA * serie.serie[i].NUM_RIPETUTE;
                        if (!isNaN(tempo)){
                            tempoTot += tempo
                        }  
                    }
                    return tempoTot * serie.NUM_RIPETUTE;
                }
                return 0;
            },
            getDistanzaTotaleSerie : function(serie){
                if (serie.serie.length == 0){
                    var distanza = serie.DISTANZA_SINGOLA * serie.NUM_RIPETUTE;
                    if (!isNaN(distanza)){
                        return distanza
                    }                    
                }
                else{
                    var distanzaTot = 0;
                    for (var i = 0 ; i < serie.serie.length ; i++){
                        var distanza = serie.serie[i].DISTANZA_SINGOLA * serie.serie[i].NUM_RIPETUTE;
                        if (!isNaN(distanza)){
                            distanzaTot += distanza
                        }  
                    }
                    return distanzaTot * serie.NUM_RIPETUTE;
                }
                return 0;
            },
            getStyleColoreSerie : function(serie){                
                if (this.serie.serie.length  == 0){
                    var intensita = utils.array.select(this.$root.arrayIntensita,"ID_INTENSITA",serie.FK_ID_INTENSITA,1)[0];
                    if (intensita != undefined) {
                        var stile = {};
                        stile['background-color'] = intensita.COLORE;
                        return stile;
                    }
                    else{
                        var stile = {};
                        stile['background-color'] = "#777";
                        return stile;
                    }
                }
                else if (this.serie.serie.length  == 1){
                    var intensita = utils.array.select(this.$root.arrayIntensita,"ID_INTENSITA",serie.serie[0].FK_ID_INTENSITA,1)[0];
                    if (intensita != undefined) {
                        var stile = {};
                        stile['background-color'] = intensita.COLORE;
                        return stile;
                    }
                    else{
                        var stile = {};
                        stile['background-color'] = "#777";
                        return stile;
                    }
                }
                else if (this.serie.serie.length > 1){
                    var stile = {};
                    stile['background-color'] = "#777";
                    return stile;
                }
                return {};
            },
            getStyleBorderColorSerie : function(serie){
                if (this.serie.serie.length  == 0){
                    var intensita = utils.array.select(this.$root.arrayIntensita,"ID_INTENSITA",serie.FK_ID_INTENSITA,1)[0];
                    if (intensita != undefined) {
                        var stile = {};
                        stile['border'] = "2px solid "+intensita.COLORE;
                        return stile;
                    }
                    else{
                        var stile = {};
                        stile['border'] = "2px solid #777";
                        return stile;
                    }
                }
                else if (this.serie.serie.length  == 1){
                    var intensita = utils.array.select(this.$root.arrayIntensita,"ID_INTENSITA",serie.serie[0].FK_ID_INTENSITA,1)[0];
                    if (intensita != undefined) {
                        var stile = {};
                        stile['border'] = "2px solid "+intensita.COLORE;
                        return stile;
                    }
                }
                else if (this.serie.serie.length > 1){
                    var stile = {};
                    stile['border'] = "2px solid #777";
                    return stile;
                }               
            },
            salvaSerie : function(serie){
                utils.put("ns/serie",serie,(response)=>{
                    utils.alert.success(response.messaggio);
                });
            },
            deleteSerie : function(serie){
                utils.alert.confirm("Sei sicuro di voler eliminare questa serie?",()=>{
                    utils.delete("ns/serie",serie,(response)=>{
                        utils.alert.success(response.messaggio);
                        this.scatenaUpdate();
                    });
                },this.$root);
            },
            nuovaSerie : function(){
                var s = {FK_ID_ALLENAMENTO:this.serie.FK_ID_ALLENAMENTO,FK_ID_SERIE:this.serie.ID_SERIE,NUM_RIPETUTE:1};
                utils.post("ns/serie",s,(response)=>{
                    this.serie.serie.push({FK_ID_ALLENAMENTO:this.serie.FK_ID_ALLENAMENTO,FK_ID_SERIE:this.serie.ID_SERIE,ID_SERIE:response.id_inserito,ORDINE:this.serie.length + 1,serie:new Array(),aperto:true});
                    utils.alert.success(response.messaggio);
                });
            },
            scatenaUpdate : function(){
                this.$emit("updateserie");
            },
            classBtn:function(a,b){
                return a == b ? "btn-secondary" : "btn-light";
            },
            salvaOrdine : function(event){
                console.log(event);
                var idSerieFrom = $(event.item).data()['serie'];
                var idSerieTo = this.getParentRecursive(event.originalEvent.target);
                var serie = utils.array.select(this.serie.serie,"ID_SERIE",idSerieFrom,1)[0];
                //CAMBIO DI ORDINE ALL'INTERNO DELLA STESSA MACROSERIE
                if (idSerieFrom == idSerieTo || idSerieTo == serie.FK_ID_SERIE){
                    utils.post("ns/serie/ordine",this.serie,(response)=>{
                        utils.alert.success(response.messaggio);
                    });
                }
                else{
                    utils.post("ns/serie/sposta",{ID_SERIE_FROM:idSerieFrom,ID_SERIE_TO:idSerieTo},(response)=>{
                        utils.alert.success(response.messaggio);
                        this.scatenaUpdate();
                    });
                }
                
            },
            getParentRecursive : function(el){
                if ($(el).parent().data() == undefined){
                    return 0;
                }
                if ($(el).parent().data()["serie"] != undefined){
                    return $(el).parent().data()["serie"];
                }
                else{
                    return this.getParentRecursive($(el).parent());
                }
            },
            hasChildAperto : function(){
                for (var i = 0 ; i < this.serie.serie.length ; i++){
                    if (this.serie.serie[i].aperto == true){
                        return true;
                    }
                }
                return false;
            },
            getStileDivSerieChildren : function(){
                var stile = {};
                if (this.hasChildAperto()){                    
                    stile['padding'] = "4px 4px 4px 4px";
                }
                else{
                    stile['padding'] = "4px 20px 10px 20px";
                }
                return stile;
            },
            headerClickHandler : function(event){
                if ($(event.target).is("button") || $(event.target).is("i")){
                    return;
                }
                this.serie.aperto = !this.serie.aperto;
            }
        },
        computed : {
      
        },
        created : function(){
            
        },
    }
</script>

